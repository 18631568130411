import React, { useState, useEffect } from "react";
import SEO from "../../components/seo";
import BackgroundImage from "gatsby-background-image";
import { useGlobal } from "reactn";
import * as Styled from "../../css/CardFlexStyle";
import { Link } from "gatsby";
import getLang from "../../components/scripts/getLang";
import App from "../app";
import PictureFilter from "../../components/scripts/pictureFilter";
import Bokabord from "../../components/bokabord";
import CheckForHighlight from "../../components/scripts/checkForHightlight";
import { SortBySeason2 } from "../../components/scripts/SortBySeasons";
import Searchbar from "../../components/searchbar";
import { ResturangQuery } from "../../components/scripts/graphQlQuerys/restaurantQuery";
import { EnheterQuery } from "../../components/scripts/graphQlQuerys/enheterQuery";
import Hero from '../../components/hero'

import Taggar from "../../components/taggar";

const Christmastable = () => {
  const pageType = "Restaurang Julbord";
  let [english] = useGlobal("English");
  let heroData = getLang(english, ResturangQuery().allContentfulRestaurangSida);
  let heroTema = null;
  const [SearchKeyword, setSearchKeyword] = useState(' ');

  useEffect(() => {
    const hashtag = window.location.hash;
    if (hashtag) {
      const currentURL = window.location.href;
      const newURL = currentURL.split("#")[0];
      window.history.replaceState("", "christmastable", newURL);
    }
  });

  if (
    (ResturangQuery("Julbord").allContentfulRestaurangTeman.edges.length !==
      0) &
    (ResturangQuery("Julbord").allContentfulRestaurangTeman.edges.length !==
      undefined)
  ) {
    heroTema = getLang(
      english,
      ResturangQuery("Julbord").allContentfulRestaurangTeman
    );
  }
  let { seoTitel, seoDescription } = heroTema[0].node;
  let { node_locale, Synligataggar, introTextjulbord } = heroData[0].node

  return (
    <App>
      <SEO title={seoTitel} description={seoDescription} />
      <Hero introText={introTextjulbord} heroTema={heroTema} data={heroData} img={'img1'} />
      <Searchbar english={english} setSearchKeyword={setSearchKeyword} SearchKeyword={SearchKeyword} />
      <Taggar mainPage="Restaurant" subPage="Julbord" data={Synligataggar} />
      <ResturantGraphql pageType={pageType} SearchKeyword={SearchKeyword} english={english} />
      <Bokabord lang={node_locale} />
    </App>
  );
};

export default Christmastable;

const ResturantGraphql = props => {
  let { SearchKeyword, english, pageType } = props;
  if (SearchKeyword === undefined) {
    SearchKeyword = "";
  }
  let regex = new RegExp(SearchKeyword.trim(), "gi");
  let regex2 = new RegExp("julbord", "gi");
  let restaurant = getLang(english, EnheterQuery());
  restaurant = SortBySeason2(restaurant);
  restaurant = CheckForHighlight(pageType, restaurant)
    .filter(edges => {
      return edges.node.sida.includes("Restaurang");
    })
    .filter(restaurant => {
      let newString = restaurant.node.skord.toString();

      return newString.match(regex) && newString.match(regex2);
    })
    .map((item, index) => {
      let orderOne = "";
      if (item.node.highlight) {
        orderOne = "orderOne";
      }
      return (
        <Link
          className={"textDecorationNone " + orderOne}
          key={index}
          to={`/restaurant/${
            item.node.pekaMotSlug ? item.node.pekaMotSlug : item.node.slug
          }`}
        >
          <BackgroundImage
            Tag="div"
            className={"cardFlex"}
            fluid={PictureFilter("img2", item)}
            key={index}
          >
            <Styled.FlexH1 seasonClosed={item.node.seasonClosed}>
              <Styled.H2>{item.node.namn.toUpperCase()}</Styled.H2>
            </Styled.FlexH1>
          </BackgroundImage>
        </Link>
      );
    });

  return (
    <Styled.CenterDiv>
      <Styled.FlexContainer>{restaurant}</Styled.FlexContainer>
    </Styled.CenterDiv>
  );
};
